// 移动端通知公告
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop></Mtop>

    <div v-show="anno && $store.state.mhome">
      <div class="manquan">
        <img src="../../assets/img/left2.png" @click="YS.routeBack()" alt="返回上层路由图标" />
        <a>通知&公告</a>
      </div>
      <transition name="mcenter_3">
        <div v-show="enter">
          <div class="mann" v-show="message_list">
            <div
              class="mann-rech_list mann-mess_rech_list"
              v-for="(item, index) in message_list"
              :key="index"
              @click="cliannoun(index)"
            >
              <div class="mann-mess_red" v-show="!item.read_time"></div>
              <div class="mann-mess_news">
                <img :src="newShow[item.type]" alt="消息提示类型图标" />
              </div>
              <div class="mann-mess_list">
                <div>
                  <a>{{ item.title }}</a>
                  <span>{{ YS.toTime(item.create_time) }}</span>
                </div>
                <div>
                  <p>{{ item.content }}</p>
                </div>
              </div>
              <div class="mann-mess_img">
                <img src="../../assets/img/right.png" alt="打开消息提示的图标" />
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="mann_none" v-show="!message_list && islogin">
        <img src="../../assets/img/mann_none@2x.png" alt="没有消息和通知的图片" />
        <a>暂时没有消息和通知</a>
      </div>
      <div class="mann_none" v-show="!message_list && !islogin">
        <img src="../../assets/img/mann_none@2x.png" alt="没有消息和通知的图片" />
        <a>登录后即可查看消息和通知</a>
      </div>
    </div>
    <div v-show="!anno" v-for="(item, index) in message_child" :key="index">
      <div class="manquan">
        <img src="../../assets/img/left2.png" @click="anno = true" alt="消息列表的图标" />
        <a>{{ item.title }}</a>
      </div>
      <div class="mann_mess">
        <!-- <a>{{ item.title }}</a> -->
        <span>{{ YS.toTime(item.create_time) }}</span>
        <p>{{ item.content }}</p>
      </div>
    </div>
    <Mbottom></Mbottom>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mAnnoun',
  data() {
    return {
      enter: false,
      anno: true,
      message_list: null, //信息列表
      message_child: null, //消息详情
      // havedata: false, //控制 显示没有任何内容 组件展示};
      newShow: {
        0: require('../../assets/img/news1.png'),
        1: require('../../assets/img/news2.png'),
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      window.location.href = window.g.WEB_URL + '/message.html';
    }
  },
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },

    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  mounted() {
    that.enter = true;
    that.$store.commit('changelogin');
    that.$store.commit('getisvipCode');
    that.getmesslist(); // 获取消息列表
  },
  methods: {
    //点击消息list,进入消息详情
    async cliannoun(index) {
      try {
        that.anno = false;
        if (!that.message_child) that.message_child = [];
        that.message_child[0] = that.message_list[index];
        if (!that.message_child[0].read_time) {
          await that.api.user.api_messageread({ id: that.message_child[0].id });
          that.getmesslist();
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取消息列表
    async getmesslist() {
      try {
        let data = await fetch.api_messagelist({});
        if (data) {
          that.message_list = data.message_list;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scope></style>
